import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Outdoor_Cameras/IN-9020_HD/Camera_Reset/DangerBox';
import PrimaryBox from 'components/Outdoor_Cameras/IN-9020_HD/Camera_Reset/PrimaryBox';
import NavButtons from 'components/Outdoor_Cameras/IN-9020_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR HD Camera Hardware Reset",
  "path": "/Outdoor_Cameras/IN-9020_HD/Camera_Reset/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-9020 Full HD Indoor IP Camera Factory Reset",
  "image": "./P_SearchThumb_IN-9020HD_Reset.png",
  "social": "/images/Search/P_SearchThumb_IN-9020HD_Reset.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9020HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='INSTAR HD Camera Hardware Reset' dateChanged='2018-01-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-9020 Full HD Indoor IP Camera Factory Reset' image='/images/Search/P_SearchThumb_IN-9020HD_Reset.png' twitter='/images/Search/P_SearchThumb_IN-9020HD_Reset.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9020_HD/Kamera_Reset/' locationFR='/fr/Outdoor_Cameras/IN-9020_HD/Camera_Reset/' crumbLabel="Reset" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "restore-the-factory-settings-of-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#restore-the-factory-settings-of-your-camera",
        "aria-label": "restore the factory settings of your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Restore the factory settings of your camera`}</h2>
    <h3 {...{
      "id": "via-the-web-interface-of-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#via-the-web-interface-of-your-camera",
        "aria-label": "via the web interface of your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Via the web interface of your camera`}</h3>
    <p>{`If you still have access to your camera via your web browser, you can use the `}<Link to="/Web_User_Interface/1080p_Series/System/Reset/" mdxType="Link">{`Web interface to reset your camera`}</Link>{`.`}</p>
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "via-the-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#via-the-sd-card",
        "aria-label": "via the sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Via the SD card`}</h3>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`You can still reset your camera via the SD card. Create a file for it and give it the name reset (very important with no file extension!) - under Windows this is not so easy, so we have provided the necessary file for `}<OutboundLink href="https://wiki.instar.com/dl/IN-9008HD/wps_reset.zip" mdxType="OutboundLink">{`download here`}</OutboundLink>{`. The file is zipped and must first be freed from the zip container before we can use it.`}</p>
    <h4 {...{
      "id": "reset-the-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reset-the-camera",
        "aria-label": "reset the camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reset the camera`}</h4>
    <p><strong parentName="p">{`reset`}</strong>{` : Remove the SD card from your camera and connect the card to your computer via a card reader. Then place the file in the `}<strong parentName="p">{`root directory`}</strong>{` (ie directly on the card, not in a subfolder) of the memory card and then eject the SD card. To get the camera back to factory settings, please insert the SD card back into the card slot of the camera and then restart the camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/b97f6/reset_wps_by_sd_card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABqElEQVQoz5VRy27TUBD1L/ITLJBoA+IHYMGGRb8CsUYskFBVlURUpCG0eUgFWkLzsGMnflxf3+fMvR4UW9AIFoijs5nHkc7MCeYb7sACAFpQ36L8cqa+RqAMANR7oD20nXCTB3lljCw45yXnxppKCmPt3RJRTeR97ZwHRGOs1tpaIKJ4mwVZstVSUl2DkHGY8LwwlQCpJCtFwVAqEFIUjGdFmeZFsq1ypkoOQiarVTD88WWUJaMsGWzD6+vxzauX5+vlYL3oR/N+NB/Ey4arT8nqbDnrh7fDTTiIl+frxenVRfDk7N2jy+P7g+OHk9OPV6/fHD17MDzpXJwcTnu/2D2c7NiZ9jrT3sGkezB+/3jcvffhbVBw67UBIQGw+n6rnz7f+0zD3xW6GtAb6xGJ6HMaB1VZoPPonERbzG6yF0elEFprIlJap3khteaV2OXhnUV03qP3RDRi2yDcZN65uiaLkEahWqw8kXeOiMBaxpiUUillmwgQcRdhY2RcpkEYxRXnRGSMob/wR8ht6ZtjduL1euO9R0TGWDunf8HXd+K49dOK6/8R/wR7T2UClWLthAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/e4706/reset_wps_by_sd_card.avif 230w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/d1af7/reset_wps_by_sd_card.avif 460w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/7f308/reset_wps_by_sd_card.avif 920w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/44a65/reset_wps_by_sd_card.avif 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/a0b58/reset_wps_by_sd_card.webp 230w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/bc10c/reset_wps_by_sd_card.webp 460w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/966d8/reset_wps_by_sd_card.webp 920w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/cb8de/reset_wps_by_sd_card.webp 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/81c8e/reset_wps_by_sd_card.png 230w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/08a84/reset_wps_by_sd_card.png 460w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/c0255/reset_wps_by_sd_card.png 920w", "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/b97f6/reset_wps_by_sd_card.png 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1296d1a3c310c35e7d1e9dd7560d6e27/c0255/reset_wps_by_sd_card.png",
              "alt": "INSTAR IN-9020 FHD Kamera Werksreset",
              "title": "INSTAR IN-9020 FHD Kamera Werksreset",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h4 {...{
      "id": "wlan-integration-after-the-reset",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#wlan-integration-after-the-reset",
        "aria-label": "wlan integration after the reset permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WLAN integration after the reset`}</h4>
    <p><strong parentName="p">{`wps`}</strong>{` : To reconnect the camera to your Wi-Fi network after rebooting, you can create a file called `}<strong parentName="p">{`wps`}</strong>{` (very important `}<strong parentName="p">{`without file extension`}</strong>{`!) - it's not that easy on Windows, so we have the necessary file `}<OutboundLink href="https://wiki.instar.com/dl/IN-9008HD/wps_reset.zip" mdxType="OutboundLink">{`here for download provided`}</OutboundLink>{`. The file is packed and must first be freed from the zip container before we can use it. Place this file in the `}<strong parentName="p">{`root directory`}</strong>{` (ie directly on the card, not in a subfolder) of the memory card and then insert it back into the card slot of your camera. Then start the `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/" mdxType="Link">{`WPS mode of your WLAN router`}</Link>{` and restart the camera. After restarting, the camera will be in WPS mode and will try to connect to your router.`}</p>
    <h4 {...{
      "id": "where-do-i-find-the-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#where-do-i-find-the-sd-card",
        "aria-label": "where do i find the sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Where do I find the SD Card`}</h4>
    <p>{`Open the small hatch on the back of the camera head and use your tweezers to remove the MicroSD Card from the card reader:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/50ebb8bb92cf53d48e945806cd669beb/64f73/IN-9020FHD_SD_Card.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB6ly6pJgH/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAIDARIj/9oACAEBAAEFArU89prladPOjqUoz6f/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAgEFAAAAAAAAAAAAAAAAAQIREBIhIjFh/9oACAEBAAY/AlKD2L1OvRtdFKRyd4//xAAcEAADAAEFAAAAAAAAAAAAAAAAAREQITFRYXH/2gAIAQEAAT8hh21BV6ERDHlrYh7UlwakYx//2gAMAwEAAgADAAAAEDcP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QGf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQADAAIDAAAAAAAAAAAAAAEAESEQgUFRof/aAAgBAQABPxBqvCWF4+al001sgHtmKHjdYZOuAk+xqHLBcOuP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50ebb8bb92cf53d48e945806cd669beb/e4706/IN-9020FHD_SD_Card.avif 230w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/d1af7/IN-9020FHD_SD_Card.avif 460w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/7f308/IN-9020FHD_SD_Card.avif 920w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/44a65/IN-9020FHD_SD_Card.avif 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/50ebb8bb92cf53d48e945806cd669beb/a0b58/IN-9020FHD_SD_Card.webp 230w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/bc10c/IN-9020FHD_SD_Card.webp 460w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/966d8/IN-9020FHD_SD_Card.webp 920w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/cb8de/IN-9020FHD_SD_Card.webp 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50ebb8bb92cf53d48e945806cd669beb/e83b4/IN-9020FHD_SD_Card.jpg 230w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/e41a8/IN-9020FHD_SD_Card.jpg 460w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/4d836/IN-9020FHD_SD_Card.jpg 920w", "/en/static/50ebb8bb92cf53d48e945806cd669beb/64f73/IN-9020FHD_SD_Card.jpg 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/50ebb8bb92cf53d48e945806cd669beb/4d836/IN-9020FHD_SD_Card.jpg",
              "alt": "INSTAR IN-9020 FHD Kamera Werksreset",
              "title": "INSTAR IN-9020 FHD Kamera Werksreset",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      